module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-R9KTRL5CEL"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.solitaire-deckedout.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","title":"Solitaire: Decked Out ― Play Classic Klondike Card Game","description":"The Best Solitaire App. Designed for Android phones, iPhones, and iPads. Enjoy the classic game of Solitaire you know and love!","openGraph":{"url":"https://www.solitaire-deckedout.com/","title":"Solitaire: Decked Out ― Play Classic Klondike Card Game","description":"The Best Solitaire App. Designed for Android phones, iPhones, and iPads. Enjoy the classic game of Solitaire you know and love!","site_name":"Solitaire: Decked Out","type":"website"},"twitter":{"cardType":"app","site":"@devsisters","handle":"@solitairfree"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Solitaire: Decked Out ― Play Classic Klondike Card Game","short_name":"Solitaire: Decked Out","start_url":"/","background_color":"#4A7E36","theme_color":"#5A9C39","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3837d568b0a69447dea4568f4615d7b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
